#mc_embed_signup form {
  padding: 1rem;
}

#mc_embed_signup input.email {
  height: 42px;
  margin: 0;
  padding: 0 1rem;
}

#mc_embed_signup input.button {
  width: 175px;
  height: 42px;
  margin: 0;
  font-size: 13px;
  background-color: #9C4DCC;
}

#mc_embed_signup input.button:hover {
  background-color: #723995;
}

@media (max-width: 600px) {
  #mc_embed_signup input.email {
    margin: 0 0 1rem 0;
  }
}
